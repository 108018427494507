/*______________________________________________________________________________ 
                         Define the color variables here
______________________________________________________________________________  */

:root {
  --box-color1: #676767;
  --box-border: rgb(52, 52, 52);
  --box-shadow: rgba(117, 113, 113, 0.304);
  --button-color1: rgb(162, 162, 162);
  --back-gradient: linear-gradient(230.5deg, #b5b0b0 9.98%, rgba(94, 83, 83, 0) 89.57%);
  --primary-color: #6fa209;
  --primary-dark: #22ba2f;
  --secondary-color: #ffea00;
  --secondary-dark: #e3d002;
  --overlay: rgba(104, 104, 104, 0.117);
  /* --overlay: rgba(0, 0, 0, 0.129); */
  background-color: rgb(0, 0, 0);
  color: rgb(5, 3, 3);
}

/*______________________________________________________________________________ 
                                    Body
______________________________________________________________________________  */

body {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: var(--back-gradient);
  font-family: "Roboto", sans-serif;
  --webkit-font-smoothing: antialiased;
  --moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
}

.button {
  margin-right: 2rem;
  width: 200px;
  /* min-width: 200px;
  max-width: 500px; */
  padding: 17px 20px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}
