@font-face {
  font-family: "megalomaniac";
  src: url("../src/Fonts/megalomaniac-webfont.woff2") format("woff2"), url("../src/Fonts/megalomaniac-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/Fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
