/*______________________________________________________________________________ 
                                Project Card [Arbeid.js - 
                  Mapping projectsGithub from ../Txt/projectConst.js]
______________________________________________________________________________  */

.container {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 50px; */
  gap: 20px;
  margin-bottom: 5rem;
}

.arbeidText > p {
  padding-top: 45px;
  margin: 1rem;
  padding: 1rem;
}

.sisteProsjekter > p {
  border-bottom: solid var(--primary-color) 0.5rem;
  padding: 0;
  font-family: "megalomaniac";
  font-size: 4rem;
  color: white;
  margin: 5rem 0 2rem;
}

.sisteProsjekter {
  display: flex;
  justify-content: center;
  height: 11.6rem;
}

.ProsjektText {
  display: flex;
  justify-content: center;
}

.projectContainer {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 270px;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 1px 2px var(--box-shadow);
}

.projectCardBottom > a {
  text-decoration: none;
  color: white;
}

.projectContainer:hover {
  transform: translateY(2px) scale(1.05);
}

.projectCard {
  border-radius: 17px;
  width: 250px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgb(191, 191, 191) 0%, rgba(43, 47, 47, 1) 44%, rgba(117, 117, 117, 1) 93%);
}

.projectCard > a > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.projectCard > a {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.projectCardBottom {
  line-height: 0%;
  padding-left: 10px;
  border-radius: 17px;
}

.projectCardBottom > h4 {
  font-weight: 600;
}

.projectContainer a:hover {
  color: var(--primary-dark);
}
