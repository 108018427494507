/*______________________________________________________________________________ 
                                Footer [Footer.js]
______________________________________________________________________________  */

.FooterLinks {
  max-width: 1000px;
  margin: auto;
  font-family: Roboto;
  background: var(--box-color1);
  height: 6rem;
  border-top: 2px solid black;
}
.FooterLinks > a:hover {
  filter: invert(1);
}

.FooterLinks img {
  margin-top: 0.5rem;
  width: 50px;
  height: 50px;
  object-fit: contain;
  padding: 0 40px;
  filter: invert(1);
  border-radius: 50%;
}

.FooterLinks p {
  padding-top: 0.2rem;
  font-size: 1rem;
}
