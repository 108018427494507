.navbar {
  display: flex;
  max-width: 1000px;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
}

.header {
  font-family: megalomaniac;
  height: auto;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: 0.3s ease-in;
  width: 100%;
  z-index: 1;
  margin: auto;
  font-family: "megalomaniac";
  position: fixed;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  transition: 0.3s ease-in;
  overflow: hidden;
}

.header .navbar {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.625);
  display: flex;
  justify-content: space-between;
  margin: auto;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.8px);
  -webkit-backdrop-filter: blur(4.8px);
}

.header ul {
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.5rem;
  margin-right: 5rem;
}

.header .navbar img {
  padding: 0.3rem 1rem;
  cursor: pointer;
}

.header .navbar .nav-header {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: white;
  list-style: none;
  cursor: pointer;
}

.navTitle {
  font-size: 0.98rem;

  color: white;
  letter-spacing: 0.12rem;
  position: absolute;
}

.navTitle {
  display: none;
}

.nav-header:hover .navTitle {
  display: block;
}

.Logo {
  filter: drop-shadow(2px 1px 2px var(--primary-color));
  /* filter: drop-shadow(px 1px 2px var(--secondary-color)); */
}

.header .nav-menu {
  display: flex;
  align-items: center;
}

.header .nav-item {
  padding-right: 2rem;
  font-weight: 400;
  list-style: none;
}

.nav-item a {
  color: rgb(255, 255, 255);
  cursor: pointer;
}

/* color font on hover for navbar items */
.nav-item a:hover {
  color: rgb(141, 140, 140);
  padding-bottom: 1px;
  border-bottom: solid 3px var(--primary-color);
}

.hamburger-menu {
  display: none;
}

.phone {
  color: white;
  text-decoration: none;
  font-weight: 700;
}

.MobileMenu {
  display: none;
}
@media screen and (max-width: 940px) {
  .header {
    height: auto;
    max-width: 100%;
  }
  .header .navbar {
    height: 88px;
    max-width: 100%;
    justify-content: center;
    flex-direction: row;
  }

  .header ul {
    font-size: 1.5rem;
    margin-right: 5rem;
    margin-top: 0;
  }

  .hamburger-menu {
    display: block;
    margin-bottom: 1.2rem;
  }
  .hamburger-menu:hover {
    background-color: var(--primary-color);
  }
  .nav-menu {
    position: fixed;
    left: +100%;
    top: 55px;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.535);
    width: 100%;
    height: 2rem;
    z-index: 0;
    justify-content: center;
    transition: 0.3s;
  }
  .nav-menu.active {
    left: 0;
    padding: 0;
  }

  .nav-item a {
    font-family: "megalomaniac";
    margin: 1rem;
    font-size: 1.4rem;
  }

  /* .header .navbar img {
    height: 55px;
  } */

  .phone {
    color: white;
    text-decoration: none;
    font-weight: 700;
  }
  .nav-item a:hover {
    color: rgb(141, 140, 140);
    padding-bottom: 1px;
    border-bottom: none;
  }
}

@media screen and (max-width: 380px) {
  /* .RingText {
    display: none;
  } */

  .Top a {
    padding: 0;
    font-size: 0.9em;
  }
  .Top p {
    padding: 0;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 370px) {
  .openText {
    display: none;
  }

  .closedText {
    display: none;
  }
}
