/*______________________________________________________________________________ 
                                Om (About) [Info.js]
/* ______________________________________________________________________________  */
.info {
  max-width: 984px;
  margin: auto;
}

.infoHeader {
  display: flex;
  justify-content: center;
  height: 5.6rem;
  padding: 1rem 0 1rem;
}

.infoHeader > p {
  border-bottom: solid var(--primary-color) 0.5rem;
  font-family: "megalomaniac";
  font-size: 4rem;
  color: white;
  margin: 0;
  padding-top: 1rem;
}

.infoComponent {
  padding: 2rem;
  display: flex;
  justify-content: space-around;
}

/* Border for info */
.info {
  padding: 0;
  color: white;
  border-width: 0.5rem;
  border-style: solid;
  border-image: linear-gradient(to bottom, var(--box-color1), rgba(0, 0, 0, 0)) 1 100%;
}

.infoComponent > p {
  white-space: pre-wrap;
  text-align: center;
  font-style: bold;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
  color: white;
  padding-left: 5rem;
  margin-bottom: 3rem;
}

.imgRound {
  width: 15rem;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  border-radius: 50%;
}

.imgRound:hover {
  transform: translateY(10px) scale(1.2);
}

.info > p {
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.infoTextBox p:first-child {
  font-size: 1.8rem;
  color: white;
}

.infoTextBox p:nth-child(2) {
  color: black;
  font-size: 1.5rem;
}

.infoTextBox p:nth-child(3) {
  font-size: 1.8rem;
  font-style: italic;
  color: white;
}

.infoTextBox p:nth-child(4) {
  font-size: 1.5rem;
  font-style: italic;
  color: black;
}

.infoTextBox {
  width: 450px;
  height: auto;
  text-align: center;
  line-height: 30px;
}

.moreInfo:hover {
  border-bottom: solid 3px var(--primary-color);
}

.moreInfo2:hover {
  border-bottom: solid 3px var(--primary-color);
}

.moreInfo {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: -1rem 5rem;
  position: absolute;
  margin-top: -2rem;
}

.moreInfo2 {
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  margin: 1rem 2rem;
  position: absolute;
}

.moreinfoComponent {
  display: flex;
  justify-content: center;
  padding: 0 4rem;
  flex-direction: column;
}

.infoLinks {
  color: black;
}

.moreinfoComponent > h4 {
  color: black;
  text-align: center;
}

.aboutImage {
  object-fit: contain;
  width: 450px;
  height: 520px;
  border-radius: 20px;
  align-items: center;
}

.cvButttonContainer {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 940px) {
  .info > p {
    padding: 1rem;
  }

  .infoTextBox > p {
    padding: 1rem;
  }

  .imgRound {
    width: 10rem;
  }

  .infoContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .infoTextBox {
    width: 80%;
    margin-top: 2rem;
  }

  .aboutImage {
    margin-top: 2rem;
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .moreInfo {
    margin-bottom: 2rem;
  }
  .infoComponent {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .infoComponent > p {
    padding: 0.2rem;
  }
  .imgRound {
    width: 13rem;
  }
}
